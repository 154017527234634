<template>
  <section ref="MainContent" class="relative z-10 w-full py-20 overflow-hidden">
    <header class="text-center mb-28">
      <p
        data-section-title
        class="uppercase sm:text-[80px] text-[48px] leading-none sm:mb-5 mb-3 font-bold text-primary"
      >
        news
      </p>
      <h2 data-section-subtitle class="text-2xl font-bold">最新消息</h2>
    </header>

    <ol data-section-content class="flex flex-wrap items-stretch -mx-5">
      <li
        v-for="(item, item_index) in news_data"
        :key="`article_${item_index}`"
        :class="GetCardStyle(item_index + 1)"
        class="w-full px-5 mb-10 sm:w-1/2 md:w-1/3"
      >
        <div class="w-full">
          <router-link
            :to="`/news/${item.NewsID}`"
            :aria-label="item.Title"
            class="block w-full mb-5 overflow-hidden aspect-square"
          >
            <img
              v-lazy="$ImageUrl(item.Image1)"
              @load="$RefreshScrollTrigger"
              :alt="item.Title"
              class="block object-cover w-full h-full"
            />
          </router-link>
          <div class="relative w-full mb-2">
            <h3 class="text-xl font-black truncate">{{ item.Title }}</h3>
          </div>
          <div class="flex items-center w-full mb-5">
            <p class="mr-3 text-xs font-medium text-zinc-500">
              {{ item.created_at.slice(0, 10) }}
            </p>
            <p class="px-2 py-1 text-xs border border-primary text-primary">
              {{ item.CategoryTitle }}
            </p>
          </div>
          <div class="block w-full">
            <router-link
              :to="`/news/${item.NewsID}`"
              class="block w-full px-10 py-3 text-sm font-bold text-center text-white transition-colors duration-300 border border-black rounded-md bg-primary hover:md:text-black hover:md:bg-transparent"
            >
              查看完整內容
            </router-link>
          </div>
        </div>
      </li>
    </ol>
    <div data-section-content class="flex justify-center w-full">
      <router-link
        to="/news"
        class="w-full py-5 font-bold text-center text-white transition-colors duration-300 bg-black border border-black rounded-md md:w-1/3 sm:w-1/2 hover:md:bg-primary"
      >
        查看全部文章
      </router-link>
    </div>
  </section>
</template>

<script>
import { CommonSection } from '@/gsap/CommonSection.js';
export default {
  name: 'HomeNewsArticleSection',
  data() {
    return {
      // news_data: [
      //   {
      //     NewsID: 1,
      //     Title: '單車旅遊神隊友- 全新電輔車上線囉！!',
      //     CategoryTitle: '最新消息',
      //     Image1: '/img/article/article_1.webp',
      //     created_at: '2023/05/23 10:01:20',
      //   },
      //   {
      //     NewsID: 2,
      //     Title: '單車旅遊神隊友- 全新電輔車上線囉！!',
      //     CategoryTitle: '最新消息',
      //     Image1: '/img/article/article_2.webp',
      //     created_at: '2023/05/23 10:01:20',
      //   },
      //   {
      //     NewsID: 3,
      //     Title: '單車旅遊神隊友- 全新電輔車上線囉！!',
      //     CategoryTitle: '最新消息',
      //     Image1: '/img/article/article_3.webp',
      //     created_at: '2023/05/23 10:01:20',
      //   },
      // ],
      section_gsap: null,
    };
  },
  methods: {
    GetCardStyle(index) {
      let style = '';
      if (index % 3 == 0) {
        style += ' md:border-r-0 border-black';
      } else {
        style += ' md:border-r border-black';
      }

      if (index % 2 == 0) {
        style += ' sm:border-r-0 ';
      } else {
        style += ' sm:border-r ';
      }

      return style;
    },
    SetGsap() {
      this.section_gsap.setup();
    },
  },
  computed: {
    news_data() {
      return this.$store.state.news_data.slice(0, 3);
    },
  },
  mounted() {
    this.section_gsap = new CommonSection(this.$refs.MainContent);
    this.section_gsap.reset();
  },
};
</script>
