<template>
  <section ref="MainContent" class="relative z-10 w-full py-20">
    <header class="text-center mb-28">
      <p
        data-section-title
        class="uppercase sm:text-[80px] text-[48px] leading-none sm:mb-5 mb-3 font-bold text-primary"
      >
        faq
      </p>
      <h2 data-section-subtitle class="text-2xl font-bold">常見問題</h2>
    </header>
    <ol
      data-section-content
      class="flex flex-wrap items-start justify-between -mx-5"
    >
      <li
        v-for="(item, item_index) in question_data"
        :key="`question_${item_index}`"
        class="w-full px-5 mb-5 md:w-1/2 sm:mb-10"
      >
        <div class="overflow-hidden border border-black rounded-md">
          <div
            @click="OpenQuestion(item_index)"
            class="flex items-center justify-between px-8 py-5 transition-colors duration-300 hover:md:text-white hover:md:bg-primary"
          >
            <p class="font-bold">{{ item.Title }}</p>
            <span class="icon-chevron-right"></span>
          </div>
          <div
            :class="
              open_list.indexOf(item_index) != -1
                ? 'py-5 px-8 max-h-fit'
                : ' py-0 px-0 max-h-0'
            "
            class="w-full whitespace-pre-wrap transition-all duration-300 text-zinc-500 editor_content"
            v-html="item.Content"
          ></div>
        </div>
      </li>
    </ol>
  </section>
</template>

<script>
import { CommonSection } from '@/gsap/CommonSection.js';
export default {
  name: 'HomeQuestionSection',
  data() {
    return {
      section_gsap: null,
      open_list: [],
    };
  },
  methods: {
    SetGsap() {
      this.section_gsap.setup();
    },
    OpenQuestion(id) {
      if (this.open_list.indexOf(id) == -1) {
        this.open_list.push(id);
      } else {
        const index = this.open_list.indexOf(id);
        this.open_list.splice(index, 1);
      }
    },
  },
  computed: {
    question_data() {
      return this.$store.state.question_data;
    },
  },
  mounted() {
    this.section_gsap = new CommonSection(this.$refs.MainContent);
    this.section_gsap.reset();
  },
};
</script>
