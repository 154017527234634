<template>
  <main
    ref="MainContent"
    class="relative z-10 w-full min-h-screen overflow-x-hidden pt-36"
  >
    <BreadCrumb :path="bread_crumb" />
    <div class="w-full max-w-screen-xl px-5 mx-auto xl:px-0 sm:px-10">
      <div class="relative z-10 w-full overflow-hidden rounded-2xl">
        <img
          alt="日月潭單車出租 電輔車出租"
          :src="$ImageUrl($GetColumn('banner_image_lg'))"
          class="hidden w-full md:block"
        />
        <img
          alt="日月潭單車出租 電輔車出租"
          :src="$ImageUrl($GetColumn('banner_image_sm'))"
          class="block w-full md:hidden"
        />
      </div>
      <ProductSection ref="ProductSection" />
      <ShopInfoSection ref="ShopInfoSection" />
      <BikeArticleSection ref="BikeArticleSection" />
      <NewsArticleSection ref="NewsArticleSection" />
      <QuestionSection ref="QuestionSection" />
    </div>
  </main>
</template>

<script>
import BreadCrumb from '@/components/BreadCrumb.vue';
import ProductSection from '@/components/home/ProductSection.vue';
import ShopInfoSection from '@/components/home/ShopInfoSection.vue';
import BikeArticleSection from '@/components/home/BikeArticleSection.vue';
import NewsArticleSection from '@/components/home/NewsArticleSection.vue';
import QuestionSection from '@/components/home/QuestionSection.vue';
import { GetMetaData } from '@/common/meta';

export default {
  name: 'HomeView',
  components: {
    ProductSection,
    ShopInfoSection,
    BikeArticleSection,
    NewsArticleSection,
    QuestionSection,
    BreadCrumb,
  },
  data() {
    return {
      meta_data: null,
      bread_crumb: [
        {
          title: '首頁',
          link: '/',
        },
      ],
    };
  },
  methods: {
    SetGsap() {
      this.$refs.ProductSection.SetGsap();
      this.$refs.ShopInfoSection.SetGsap();
      this.$refs.BikeArticleSection.SetGsap();
      this.$refs.NewsArticleSection.SetGsap();
      this.$refs.QuestionSection.SetGsap();
    },
  },
  watch: {
    '$store.state.image_loaded'() {
      this.$store.state.image_loaded ? this.SetGsap() : '';
    },
  },
  mounted() {
    this.meta_data = GetMetaData('日月潭環湖最佳選擇');
    this.$PageReady(this.meta_data.title);
    this.$emit('load-image');
  },
  metaInfo() {
    return this.meta_data;
  },
};
</script>
