import axios from "axios";
import store from "@/store/index.js";

let baseURL = process.env.VUE_APP_BASE_API;
// 建立axios例項
const service = axios.create({
  baseURL: baseURL,
  timeout: 600000, // 請求超時時間
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
  },
});

const err = (error) => {
  if (error.response) {
    let data = error.response.data;
    console.log(`message: ${data.msg}`);
  }
  return Promise.reject(error);
};

// request攔截器
service.interceptors.request.use(
  (config) => {
    config.loading ? store.commit("SetLoading", 1) : "";
    return config;
  },
  (error) => {
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error);
  }
);
// respone攔截器
service.interceptors.response.use((response) => {
  response.config.loading ? store.commit("SetLoading", -1) : "";
  return response.data;
}, err);

/*
 *  get請求
 *  url:請求地址
 *  params:引數
 * */
export function get(url, params = {}, loading = true) {
  return new Promise((resolve, reject) => {
    service({
      url: url,
      method: "get",
      params: params,
      loading: loading,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/*
 *  post請求
 *  url:請求地址
 *  params:引數
 * */
export function post(url, params = {}, loading = true) {
  return new Promise((resolve, reject) => {
    service({
      url: url,
      method: "post",
      data: params,
      loading: loading,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/*
 *  put請求
 *  url:請求地址
 *  params:引數
 * */
export function put(url, params = {}, loading = true) {
  return new Promise((resolve, reject) => {
    service({
      url: url,
      method: "put",
      data: params,
      loading: loading,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/*
 *  patch請求
 *  url:請求地址
 *  params:引數
 * */
export function patch(url, params = {}, loading = true) {
  return new Promise((resolve, reject) => {
    service({
      url: url,
      method: "patch",
      data: params,
      headers: {
        "Content-Type": "multipart/form-data;charset=UTF-8",
      },
      loading: loading,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/*
 *  delete請求
 *  url:請求地址
 *  params:引數
 * */
export function del(url, params = {}, loading = true) {
  return new Promise((resolve, reject) => {
    service({
      url: url,
      method: "delete",
      data: params,
      headers: {
        "Content-Type": "multipart/form-data;charset=UTF-8",
      },
      loading: loading,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/*
 *  post_form請求
 *  url:請求地址
 *  params:引數
 * */
export function post_form(url, params = {}, loading = true) {
  return new Promise((resolve, reject) => {
    service({
      url: url,
      method: "post",
      data: params,
      headers: {
        "Content-Type": "multipart/form-data;charset=UTF-8",
      },
      loading: loading,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export default {
  get,
  post,
  put,
  del,
  patch,
};
