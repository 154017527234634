<template>
  <div class="fixed top-0 left-0 z-20 w-full">
    <div
      class="relative z-10 flex items-center justify-between w-full max-w-screen-xl px-5 py-5 mx-auto xl:px-0 sm:px-10"
    >
      <router-link to="/" aria-label="快樂阿隆電動自行車租賃">
        <img
          src="/img/nav_logo.svg"
          alt="快樂阿隆電動自行車租賃"
          class="block w-auto h-12 lg:h-20 sm:h-14"
        />
      </router-link>

      <nav
        class="items-center justify-between hidden px-3 bg-white rounded-full md:flex"
      >
        <ol class="flex items-stretch pl-3 mr-3 lg:mr-10">
          <li
            v-for="(item, item_index) in menu_data"
            :key="`menu_item_${item_index}`"
          >
            <router-link
              :to="item.link"
              class="block px-3 py-5 text-sm font-bold transition-colors duration-300 hover:md:text-primary"
            >
              {{ item.title }}
            </router-link>
          </li>
        </ol>

        <router-link
          to="/reservation"
          class="py-3 text-sm font-bold text-white transition-colors duration-300 border rounded-full bg-primary px-9 hover:md:bg-white border-primary hover:md:text-primary"
        >
          立即預約
        </router-link>
      </nav>

      <div
        class="flex items-center justify-between rounded-full md:hidden sm:bg-white sm:px-3 sm:py-2"
      >
        <router-link
          to="/reservation"
          class="px-6 py-3 mr-3 text-sm font-bold text-white rounded-full bg-primary sm:px-9"
        >
          立即預約
        </router-link>
        <button
          @click="$emit('open-menu')"
          class="bg-white rounded-full w-11 h-11"
          aria-label="開啟選單"
        >
          <span class="text-xl icon-menu sm:text-2xl"></span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainHeader',
  props: {
    menu_data: {
      require: true,
      type: Array,
    },
  },
};
</script>
