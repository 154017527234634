<template>
  <section ref="MainContent" class="relative z-10 w-full py-20 overflow-hidden">
    <header class="text-center mb-28">
      <p
        data-section-title
        class="uppercase sm:text-[80px] text-[48px] leading-none sm:mb-5 mb-3 font-bold text-primary"
      >
        bike routes
      </p>
      <h2 data-section-subtitle class="text-2xl font-bold">單車路線介紹</h2>
    </header>

    <ol data-section-content class="flex flex-wrap items-stretch -mx-5">
      <li
        v-for="(item, item_index) in bike_article_data"
        :key="`article_${item_index}`"
        :class="GetCardStyle(item_index + 1)"
        class="w-full px-5 mb-10 sm:w-1/2 md:w-1/3"
      >
        <div class="w-full">
          <router-link
            :to="`/bike_article/${item.BikeArticleID}`"
            :aria-label="item.Title"
            class="block w-full mb-5 overflow-hidden aspect-video"
          >
            <img
              v-lazy="$ImageUrl(item.Image1)"
              @load="$RefreshScrollTrigger"
              :alt="item.Title"
              class="block object-cover w-full h-full"
            />
          </router-link>
          <div class="relative w-full mb-2">
            <h3 class="text-xl font-black truncate">{{ item.Title }}</h3>
          </div>
          <ol class="flex flex-wrap items-stretch w-full mb-5">
            <li class="w-1/3 px-1 text-sm text-center border-x border-zinc-400">
              <span class="mr-2 icon-time text-primary"></span>
              {{ item.TimeCost }}
            </li>
            <li class="w-1/3 px-1 text-sm text-center">
              <span class="mr-2 icon-road text-primary"></span>
              {{ item.Distance }}
            </li>
            <li class="w-1/3 px-1 text-sm text-center border-x border-zinc-400">
              <span class="mr-2 icon-star text-primary"></span>
              {{ item.Grade }}
            </li>
          </ol>
          <div class="block w-full">
            <router-link
              :to="`/bike_article/${item.BikeArticleID}`"
              class="block w-full px-10 py-3 text-sm font-bold text-center text-white transition-colors duration-300 border border-black rounded-md bg-primary hover:md:text-black hover:md:bg-transparent"
            >
              查看詳細資訊
            </router-link>
          </div>
        </div>
      </li>
    </ol>
    <div data-section-content class="flex justify-center w-full">
      <router-link
        to="/bike_articles"
        class="w-full py-5 font-bold text-center text-white transition-colors duration-300 bg-black border border-black rounded-md md:w-1/3 sm:w-1/2 hover:md:bg-primary"
      >
        查看全部路線
      </router-link>
    </div>
  </section>
</template>

<script>
import { CommonSection } from "@/gsap/CommonSection.js";
export default {
  name: "HomeBikeArticleSection",
  data() {
    return {
      // bike_article_data: [
      //   {
      //     BikeArticleID: 1,
      //     Title: '日月潭-向山段',
      //     Time: '2小時',
      //     Distance: '6km',
      //     Mode: '難度低',
      //     Image1: '/img/article/article_1.webp',
      //   },
      //   {
      //     BikeArticleID: 2,
      //     Title: '日月潭-向山段',
      //     Time: '2小時',
      //     Distance: '6km',
      //     Mode: '難度低',
      //     Image1: '/img/article/article_2.webp',
      //   },
      //   {
      //     BikeArticleID: 3,
      //     Title: '日月潭-向山段',
      //     Time: '2小時',
      //     Distance: '6km',
      //     Mode: '難度低',
      //     Image1: '/img/article/article_3.webp',
      //   },
      // ],
      section_gsap: null,
    };
  },
  methods: {
    GetCardStyle(index) {
      let style = "";
      if (index % 3 == 0) {
        style += " md:border-r-0 border-black";
      } else {
        style += " md:border-r border-black";
      }

      if (index % 2 == 0) {
        style += " sm:border-r-0 ";
      } else {
        style += " sm:border-r ";
      }

      return style;
    },
    SetGsap() {
      this.section_gsap.setup();
    },
  },
  computed: {
    bike_article_data() {
      return this.$store.state.bike_article_data.slice(0, 3);
    },
  },
  mounted() {
    this.section_gsap = new CommonSection(this.$refs.MainContent);
    this.section_gsap.reset();
  },
};
</script>
