import gsap from 'gsap';

export class horizon_scroll {
  setScrollForward(items, speed = 1) {
    const tl = this.horizontalLoop(items, {
      repeat: -1,
      reversed: true,
      speed: speed,
    });
    return tl;
  }
  setScrollBackward(items, speed = 1) {
    const tl = this.horizontalLoop(items, { repeat: -1, speed: speed });
    return tl;
  }

  horizontalLoop(items, config) {
    // 取得輪播物件dom
    items = gsap.utils.toArray(items);
    // 取得設定
    config = config || {};

    // 設定時間線
    let tl = gsap.timeline({
        repeat: config.repeat,
        paused: config.paused,
        defaults: { ease: 'none' },
        onReverseComplete: () =>
          tl.totalTime(tl.rawTime() + tl.duration() * 100),
      }),
      length = items.length, //輪播物件數量
      startX = items[0].offsetLeft, //開始位置，第一個物件左邊空間
      times = [],
      widths = [], //個別輪播物件寬度
      xPercents = [],
      curIndex = 0,
      pixelsPerSecond = (config.speed || 1) * 100, //移動動畫時間
      snap =
        config.snap === false ? (v) => v : gsap.utils.snap(config.snap || 1),
      // some browsers shift by a pixel to accommodate flex layouts, so for example if width is 20% the first element's width might be 242px, and the next 243px, alternating back and forth. So we snap to 5 percentage points to make things look more natural
      totalWidth, //輪播物件總長度
      curX,
      distanceToStart,
      distanceToLoop,
      item,
      i;

    // 先將個別輪播物件蟲設置原本的位置
    gsap.set(items, {
      // convert "x" to "xPercent" to make things responsive, and populate the widths/xPercents Arrays to make lookups faster.
      xPercent: (i, el) => {
        //取得個別輪播物件寬度，並放入widths陣列
        let w = (widths[i] = parseFloat(gsap.getProperty(el, 'width', 'px')));
        // 取得個別輪播物件的translateX轉換成xPercent，並放入xPercents
        xPercents[i] = snap(
          (parseFloat(gsap.getProperty(el, 'x', 'px')) / w) * 100 +
            gsap.getProperty(el, 'xPercent')
        );
        return xPercents[i];
      },
    });
    // 先將個別輪播物件蟲設置原本的位置
    gsap.set(items, { x: 0 });

    // 找最後一個輪播物件取得距離容器左邊的距離 + 位移距離 - 開始位置 + 物件寬度*放大比例 + 容器左右padding
    totalWidth =
      items[length - 1].offsetLeft +
      (xPercents[length - 1] / 100) * widths[length - 1] -
      startX +
      items[length - 1].offsetWidth *
        gsap.getProperty(items[length - 1], 'scaleX') +
      (parseFloat(config.paddingRight) || 0);

    for (i = 0; i < length; i++) {
      item = items[i];
      // 物件的位移px
      curX = (xPercents[i] / 100) * widths[i];
      // 物件的開始位置
      distanceToStart = item.offsetLeft + curX - startX;
      // 移動距離 = 開始位置+物件寬度*物件變形比例
      distanceToLoop =
        distanceToStart + widths[i] * gsap.getProperty(item, 'scaleX');

      tl.to(
        item,
        {
          xPercent: snap(((curX - distanceToLoop) / widths[i]) * 100),
          duration: distanceToLoop / pixelsPerSecond,
        },
        0
      )
        .fromTo(
          item,
          {
            xPercent: snap(
              ((curX - distanceToLoop + totalWidth) / widths[i]) * 100
            ),
          },
          {
            xPercent: xPercents[i],
            duration:
              (curX - distanceToLoop + totalWidth - curX) / pixelsPerSecond,
            immediateRender: false,
          },
          distanceToLoop / pixelsPerSecond
        )
        .add('label' + i, distanceToStart / pixelsPerSecond);
      times[i] = distanceToStart / pixelsPerSecond;
    }
    function toIndex(index, vars) {
      vars = vars || {};
      Math.abs(index - curIndex) > length / 2 &&
        (index += index > curIndex ? -length : length); // always go in the shortest direction
      let newIndex = gsap.utils.wrap(0, length, index),
        time = times[newIndex];
      if (time > tl.time() !== index > curIndex) {
        // if we're wrapping the timeline's playhead, make the proper adjustments
        vars.modifiers = { time: gsap.utils.wrap(0, tl.duration()) };
        time += tl.duration() * (index > curIndex ? 1 : -1);
      }
      curIndex = newIndex;
      vars.overwrite = true;
      return tl.tweenTo(time, vars);
    }
    tl.next = (vars) => toIndex(curIndex + 1, vars);
    tl.previous = (vars) => toIndex(curIndex - 1, vars);
    tl.current = () => curIndex;
    tl.toIndex = (index, vars) => toIndex(index, vars);
    tl.times = times;
    tl.progress(1, true).progress(0, true); // pre-render for performance
    if (config.reversed) {
      tl.vars.onReverseComplete();
      tl.reverse();
    }
    return tl;
  }
}
