import { get, post } from '@/common/request';

export function getColumn() {
  return get('/column');
}

export function getBikes() {
  return get('/bikes');
}

export function getBikeArticle() {
  return get('/bike_article');
}

export function getNews() {
  return get('/news');
}

export function getQuestion() {
  return get('/question');
}

export function sendContact({
  name,
  phone,
  date,
  start_time,
  end_time,
  email,
  bike_info,
  comment,
}) {
  let data = {
    Name: name,
    Phone: phone,
    Date: date,
    StartTime: start_time,
    EndTime: end_time,
    Email: email,
    BikeInfo: bike_info,
    Comment: comment,
  };
  return post('contactUs', data);
}
