<template>
  <aside
    ref="MainContent"
    class="fixed top-0 z-30 flex flex-col w-full h-screen left-full backdrop-blur-md"
  >
    <div
      data-menu-header
      class="relative z-10 flex items-center justify-end w-full px-5 py-5 sm:px-10"
    >
      <div
        class="flex items-center justify-between rounded-full md:hidden sm:bg-white sm:px-3 sm:py-2"
      >
        <button
          @click="Close"
          class="bg-white rounded-full w-11 h-11 text-primary"
          aria-label="關閉選單"
        >
          <span class="text-2xl icon-close"></span>
        </button>
      </div>
    </div>

    <div
      class="relative z-10 flex items-center flex-1 w-full px-5 py-5 sm:px-10"
    >
      <ol class="w-full">
        <li
          data-menu-link
          v-for="(item, item_index) in menu_data"
          :key="`menu_${item_index}`"
        >
          <router-link
            @click.native="Close"
            :to="item.link"
            class="block w-full py-5 text-2xl font-bold text-center text-white"
          >
            {{ item.title }}
          </router-link>
        </li>
      </ol>
    </div>

    <div
      class="absolute top-0 left-0 right-0 bottom-0 z-[1] bg-gradient-to-b from-primary to-transparent"
    ></div>
    <div
      class="absolute top-0 bottom-0 left-0 right-0 z-0 opacity-50 bg-primary"
    ></div>
  </aside>
</template>

<script>
import { MainMenu } from "@/gsap/MainMenu";
export default {
  name: "MainMenu",
  props: {
    menu_data: {
      require: true,
      type: Array,
    },
  },
  data() {
    return {
      menu_gsap: null,
    };
  },
  methods: {
    Open() {
      this.menu_gsap.open();
      document.querySelector("body").classList.add("lock");
    },
    Close() {
      this.menu_gsap.close();
      document.querySelector("body").classList.remove("lock");
    },
  },
  mounted() {
    this.menu_gsap = new MainMenu(this.$refs.MainContent);
    this.menu_gsap.reset();
  },
};
</script>
