<template>
  <section
    id="Bikes"
    ref="MainContent"
    class="relative z-10 w-full py-20 overflow-hidden"
  >
    <header class="text-center mb-28">
      <p
        data-section-title
        class="uppercase sm:text-[80px] text-[48px] leading-none sm:mb-5 mb-3 font-bold text-primary"
      >
        electric bike
      </p>
      <h2 data-section-subtitle class="text-2xl font-bold">電動自行車</h2>
    </header>

    <ol data-section-content class="flex flex-wrap items-stretch -mx-5">
      <li
        v-for="(item, item_index) in product_data"
        :key="`product_${item_index}`"
        :class="GetCardStyle(item_index + 1)"
        class="w-full px-5 mb-10 sm:w-1/2 md:w-1/3"
      >
        <div class="w-full">
          <div class="block w-full mb-5 overflow-hidden aspect-square">
            <img
              :src="$ImageUrl(item.Image1)"
              :alt="item.Title"
              width="400"
              height="400"
              class="block object-cover w-full h-full"
            />
          </div>
          <div class="relative w-full mb-2">
            <div class="relative z-10 w-full">
              <h3 class="text-2xl font-black">{{ item.Title }}</h3>
              <p class="font-medium text-primary">NT${{ item.Price }}</p>
            </div>
            <span
              class="absolute right-0 text-6xl font-black uppercase transform -translate-y-1/2 top-1/2 opacity-30 stroke_text"
              >{{ String.fromCharCode(97 + item_index) }}</span
            >
          </div>
          <ol class="flex flex-wrap items-stretch w-full mb-5">
            <li
              v-for="(tag, tag_index) in item.Tags.split(',')"
              :key="`tag_${tag_index}`"
              class="px-1 mr-2 text-sm border rounded-sm border-zinc-500"
            >
              {{ tag }}
            </li>
          </ol>
          <div class="block w-full">
            <button
              @click="$router.push('/reservation')"
              class="w-full px-10 py-3 text-sm font-bold text-white transition-colors duration-300 border border-black rounded-md bg-primary hover:md:text-black hover:md:bg-transparent"
            >
              立即租車
            </button>
          </div>
        </div>
      </li>
    </ol>
  </section>
</template>

<script>
import { CommonSection } from '@/gsap/CommonSection.js';
export default {
  name: 'HomeProductSection',
  data() {
    return {
      section_gsap: null,
    };
  },
  methods: {
    GetCardStyle(index) {
      let style = '';
      if (index % 3 == 0) {
        style += ' md:border-r-0 border-black';
      } else {
        style += ' md:border-r border-black';
      }

      if (index % 2 == 0) {
        style += ' sm:border-r-0 ';
      } else {
        style += ' sm:border-r ';
      }

      return style;
    },
    SetGsap() {
      this.section_gsap.setup();
    },
  },
  computed: {
    product_data() {
      return this.$store.state.bike_data;
    },
  },
  mounted() {
    this.section_gsap = new CommonSection(this.$refs.MainContent);
    this.section_gsap.reset();
  },
};
</script>
