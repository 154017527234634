import Vue from 'vue';
import Vuex from 'vuex';
import {
  getColumn,
  getBikes,
  getBikeArticle,
  getNews,
  getQuestion,
} from '@/api/page';

Vue.use(Vuex);

/* eslint-disable no-new */
const store = new Vuex.Store({
  state: {
    main_dialog: {
      status: false, //系統訊息顯示狀態
      content: '', //系統訊息內容
    },
    image_loaded: false, //頁面圖片讀取狀態
    loading: 0, //Loading狀態，大於0則為讀取中，等於0則為讀取完畢
    common_column_data: null,
    bike_data: null,
    bike_article_data: null,
    news_data: null,
    question_data: null,
  },
  getters: {
    // 檢查是否資料都已讀取完成
    all_data_loaded(state) {
      const key_list = [
        // 放入keys
        'common_column_data',
        'bike_data',
        'bike_article_data',
        'news_data',
        'question_data',
      ];

      let error = false;
      key_list.forEach((key) => {
        state[key] == null ? (error = true) : '';
      });
      return !error;
    },
  },
  mutations: {
    // 設定頁面圖片讀取狀態
    SetImageLoaded(state, action) {
      state.image_loaded = action;
    },
    // 設定Loading狀態
    SetLoading(state, action) {
      if (action == 1) {
        state.loading += 1;
      } else {
        state.loading > 0 ? (state.loading -= 1) : '';
      }
    },
    // 設定Dialog狀態與內容文字
    SetDialog(state, { status, content }) {
      state.main_dialog.status = status;
      state.main_dialog.content = content;
    },
    // 設定State資料
    SetState(state, { key, val }) {
      state[key] = val;
    },
  },
  actions: {
    getColumnData(state) {
      getColumn().then((res) => {
        state.commit('SetState', {
          key: 'common_column_data',
          val: res.data,
        });
      });
    },
    getBikeData(state) {
      getBikes().then((res) => {
        state.commit('SetState', {
          key: 'bike_data',
          val: res.data,
        });
      });
    },
    getBikeArticle(state) {
      getBikeArticle().then((res) => {
        state.commit('SetState', {
          key: 'bike_article_data',
          val: res.data,
        });
      });
    },
    getNewsData(state) {
      getNews().then((res) => {
        state.commit('SetState', {
          key: 'news_data',
          val: res.data,
        });
      });
    },
    getQuestionData(state) {
      getQuestion().then((res) => {
        state.commit('SetState', {
          key: 'question_data',
          val: res.data,
        });
      });
    },
  },
});

export default store;
