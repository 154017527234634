import { gsap } from '@/gsap/GsapLoader';

export class CommonSection {
  constructor(el) {
    this.el = el;
    this.title = el.querySelectorAll('[data-section-title]');
    this.sub_title = el.querySelectorAll('[data-section-subtitle]');
    this.content = el.querySelectorAll('[data-section-content]');

    this.timeline = null;
  }

  reset() {
    gsap.set(this.title, { opacity: 0, y: '30px' });
    gsap.set(this.sub_title, { opacity: 0, y: '30px' });
    gsap.set(this.content, { opacity: 0, blur: 10 });
    this.timeline != null ? this.timeline.kill() : '';
    this.timeline = null;
  }

  setup() {
    this.timeline != null ? this.timeline.kill() : '';
    this.timeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.el,
        start: 'top 70%',
        toggleActions: 'restart play play reverse',
      },
    });

    this.timeline.fromTo(
      this.title,
      {
        opacity: 0,
        y: '30px',
      },
      {
        opacity: 1,
        y: '0px',
        duration: 1,
      },
      'image_in'
    );
    this.timeline.fromTo(
      this.sub_title,
      {
        opacity: 0,
        y: '30px',
      },
      {
        opacity: 1,
        delay: 0.3,
        y: '0px',
      },
      'image_in'
    );
    this.timeline.fromTo(
      this.content,
      {
        opacity: 0,
        blur: 10,
      },
      {
        opacity: 1,
        delay: 0.3,
        blur: 0,
      },
      'image_in'
    );
  }
}
