<template>
  <footer ref="MainContent" class="w-full relative z-[9] py-28 overflow-hidden">
    <div
      data-main-box
      class="w-full max-w-screen-xl px-5 mx-auto xl:px-0 sm:px-10"
    >
      <div class="flex flex-wrap items-start justify-between mb-20">
        <div class="w-full mb-10 text-center md:w-1/2 md:text-left md:mb-0">
          <img
            v-lazy="'/img/nav_logo.svg'"
            @load="$RefreshScrollTrigger"
            alt="快樂阿隆 電動自行車租賃"
            class="block mx-auto w-80 md:mr-auto md:ml-0"
          />
        </div>
        <div class="w-full text-center md:w-1/2 md:text-left">
          <ol class="mb-5">
            <li class="mb-3 font-bold md:mb-1">
              <span
                class="block font-bold text-zinc-500 md:mr-5 md:inline-block"
                >聯絡電話</span
              >{{ $GetColumn('company_phone') }}
            </li>
            <li class="mb-3 font-bold md:mb-1">
              <span
                class="block font-bold text-zinc-500 md:mr-5 md:inline-block"
                >聯絡地址</span
              >{{ $GetColumn('company_address') }}
            </li>
            <li class="font-bold">
              <span
                class="block font-bold text-zinc-500 md:mr-5 md:inline-block"
                >營業時間</span
              >{{ $GetColumn('company_time') }}
            </li>
          </ol>
          <div class="flex items-center justify-center md:justify-start">
            <a
              :href="$GetColumn('company_facebook')"
              target="_blank"
              class="flex items-center justify-center w-10 h-10 mr-3 text-white transition-colors duration-300 bg-black rounded-md hover:md:bg-primary hover:md:text-white"
            >
              <span class="text-2xl icon-facebook"></span>
            </a>
            <a
              :href="$GetColumn('company_instagram')"
              target="_blank"
              class="flex items-center justify-center w-10 h-10 text-white transition-colors duration-300 bg-black rounded-md hover:md:bg-primary hover:md:text-white"
            >
              <span class="text-2xl icon-instagram"></span>
            </a>
          </div>
        </div>
      </div>
      <div class="text-center md:text-right">
        <p class="text-sm text-zinc-500">
          © 2023 快樂阿隆電動自行車租賃。網站設計
          <a class="text-primary" href="https://www.yongxin-design.com"
            >泳欣事業有限公司</a
          >
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
import { MainFooter } from '@/gsap/MainFooter';
export default {
  name: 'MainFooter',
  data() {
    return {
      footer_gsap: null,
    };
  },
  methods: {
    SetGsap() {
      this.footer_gsap = new MainFooter(this.$refs.MainContent);
      this.footer_gsap.reset();
      this.footer_gsap.setup();
    },
  },
  watch: {
    '$store.state.image_loaded'() {
      if (this.$store.state.image_loaded) {
        this.$nextTick(() => {
          this.SetGsap();
        });
      }
    },
  },
  mounted() {},
};
</script>
