import { gsap } from '@/gsap/GsapLoader';

export class Dialog {
  constructor(el) {
    this.el = el;
    this.dialog_box = el.querySelector('[data-dialog-box]');
    this.background = el.querySelector('[data-dialog-background]');

    this.timeline = null;
  }

  reset() {
    gsap.set(this.el, { x: '0%' });
    gsap.set(this.dialog_box, { y: '30px', opacity: 0, blur: 10 });
    gsap.set(this.background, { opacity: 0, blur: 10 });
    this.timeline != null ? this.timeline.kill() : '';
    this.timeline = null;
  }

  open() {
    this.timeline != null ? this.timeline.kill() : '';
    this.timeline = gsap.timeline();

    this.timeline.set(this.el, { x: '-100%' });
    this.timeline.fromTo(
      this.background,
      {
        opacity: 0,
        blur: 10,
      },
      {
        opacity: 1,
        blur: 0,
      },
      'dialog_show'
    );
    this.timeline.fromTo(
      this.dialog_box,
      {
        y: '30px',
        opacity: 0,
        blur: 10,
      },
      {
        y: '0px',
        opacity: 1,
        blur: 0,
      },
      'dialog_show'
    );
  }

  close() {
    this.timeline != null ? this.timeline.kill() : '';
    this.timeline = gsap.timeline();

    this.timeline.fromTo(
      this.background,
      {
        opacity: 1,
        blur: 0,
      },
      {
        opacity: 0,
        blur: 10,
      },
      'dialog_hidden'
    );
    this.timeline.fromTo(
      this.dialog_box,
      {
        y: '0px',
        opacity: 1,
        blur: 0,
      },
      {
        y: '30px',
        opacity: 0,
        blur: 10,
      },
      'dialog_hidden'
    );
    this.timeline.set(this.el, { x: '0%' }, 'remove');
  }
}
