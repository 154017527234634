<template>
  <div
    id="app"
    class="relative z-10 flex flex-col w-full min-h-screen bg-background"
  >
    <MainLoading />
    <MainHeader :menu_data="menu_data" @open-menu="OpenMenu" />

    <template v-if="all_data_loaded">
      <MainMenu :menu_data="menu_data" ref="MainMenu" />
      <MainDialog />
      <div class="relative z-10 flex-1 min-h-screen">
        <router-view
          class="relative z-10 min-h-screen"
          @load-image="LoadImage"
        />
      </div>
      <ContactFooter />
      <MainFooter ref="MainFooter" />
    </template>
    <div
      class="fixed top-0 bottom-0 left-0 right-0 z-0 bg-repeat opacity-25 main_fixed_bg"
    ></div>
  </div>
</template>

<script>
import { ImageLoader } from "@/gsap/ImageLoader";
// import { ScrollTrigger } from '@/gsap/GsapLoader';
import MainDialog from "@/components/MainDialog.vue";
import MainLoading from "@/components/MainLoading.vue";
import ContactFooter from "@/components/ContactFooter.vue";
import MainFooter from "@/components/MainFooter.vue";
import MainHeader from "@/components/MainHeader.vue";
import MainMenu from "@/components/MainMenu.vue";
import { mapState } from "vuex";
import { APIViewPage } from "@/common/pixel_methods";
export default {
  name: "App",
  components: {
    MainDialog,
    MainLoading,
    ContactFooter,
    MainFooter,
    MainHeader,
    MainMenu,
  },
  data() {
    return {
      image_loader: null,
      menu_data: [
        {
          title: "單車款式",
          link: "/#Bikes",
        },
        {
          title: "店家資訊",
          link: "/#ShopInfo",
        },
        {
          title: "單車路線",
          link: "/bike_articles",
        },
        {
          title: "最新消息",
          link: "/news",
        },
      ],
    };
  },
  methods: {
    LoadImage() {
      // 確認頁面圖片讀取狀況
      this.$nextTick(() => {
        this.image_loader.LoadImage();
      });
    },
    OpenMenu() {
      this.$refs.MainMenu.Open();
    },
    CloseMenu() {
      this.$refs.MainMenu.Close();
    },
    CheckPageData() {
      // 檢查網站各項資料是否已經存在，如果不存在則Call API讀取資料

      // 通用欄位資料
      this.common_column_data == null
        ? this.$store.dispatch("getColumnData")
        : "";

      // 腳踏車
      this.bike_data == null ? this.$store.dispatch("getBikeData") : "";

      // 單車路線
      this.bike_article_data == null
        ? this.$store.dispatch("getBikeArticle")
        : "";

      // 最新消息
      this.news_data == null ? this.$store.dispatch("getNewsData") : "";

      // 問答
      this.question_data == null ? this.$store.dispatch("getQuestionData") : "";
    },
  },
  computed: {
    ...mapState([
      "common_column_data",
      "bike_data",
      "bike_article_data",
      "news_data",
      "question_data",
    ]),
    all_data_loaded() {
      return this.$store.getters.all_data_loaded;
    },
  },
  watch: {
    $route() {
      APIViewPage();
    },
  },
  mounted() {
    this.image_loader = new ImageLoader();
    APIViewPage();
  },
  created() {
    this.CheckPageData();
  },
};
</script>

<style src="@/assets/css/output.css"></style>
