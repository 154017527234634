import store from '@/store/index';
import router from '@/router/index';

export function GetMatchMetaData() {
  const column_title = router.history.current.name;
  let meta_data_list = store.state.common_column_data;
  let title = meta_data_list.filter(
    (item) => item.Title == `meta_title_${column_title}`
  );
  let content = meta_data_list.filter(
    (item) => item.Title == `meta_content_${column_title}`
  );
  let image = meta_data_list.filter(
    (item) => item.Title == `meta_image_${column_title}`
  );
  if (title.length > 0) {
    return {
      title: title[0].Content,
      content: content[0].Content.replaceAll(/<[^>]+>/g, ''),
      image: GetImageUrl(image[0].Content),
    };
  } else {
    return false;
  }
}

export function GetBaseMetaData() {
  let meta_data_list = store.state.common_column_data;
  let base_title = meta_data_list.filter(
    (item) => item.Title == 'seo_title'
  )[0];
  let base_content = meta_data_list.filter(
    (item) => item.Title == 'seo_content'
  )[0];
  let base_image = meta_data_list.filter(
    (item) => item.Title == 'seo_image'
  )[0];
  return {
    title: base_title ? base_title.Content : '',
    content: base_content
      ? base_content.Content.replaceAll(/<[^>]+>/g, '')
      : '',
    image: base_image ? GetImageUrl(base_image.Content) : '',
  };
}

export function GetMetaData(title, content = '', image = '') {
  let default_meta = GetBaseMetaData();
  default_meta.content.length > 155
    ? (default_meta.content = default_meta.content.slice(0, 155) + '...')
    : '';
  return {
    title: title + ' | ' + default_meta.title,
    meta: [
      {
        property: 'og:url',
        name: 'og:url',
        content: process.env.VUE_APP_BASEURL + router.history.current.fullPath,
        vmid: 'og:url',
      },
      {
        property: 'og:title',
        name: 'og:title',
        content: title + ' | ' + default_meta.title,
        vmid: 'og:title',
      },
      {
        property: 'og:description',
        name: 'og:description',
        content: content == '' ? default_meta.content : content,
        vmid: 'og:description',
      },
      {
        property: 'twitter:description',
        name: 'twitter:description',
        content: content == '' ? default_meta.content : content,
        vmid: 'twitter:description',
      },
      {
        property: 'description',
        name: 'description',
        content: content == '' ? default_meta.content : content,
        vmid: 'description',
      },
      {
        property: 'og:image',
        name: 'og:image',
        content: image == '' ? default_meta.image : image,
        vmid: 'og:image',
      },
      {
        property: 'apple-mobile-web-app-title',
        name: 'apple-mobile-web-app-title',
        content: title + ' | ' + default_meta.title,
        vmid: 'apple-mobile-web-app-title',
      },
      {
        property: 'application-name',
        name: 'application-name',
        content: title + ' | ' + default_meta.title,
        vmid: 'application-name',
      },
      {
        property: 'og:site_name',
        name: 'og:site_name',
        content: '官方網站' + ' | ' + default_meta.title,
        vmid: 'og:site_name',
      },
      {
        property: 'og:type',
        name: 'og:type',
        content: 'website',
        vmid: 'og:type',
      },
      {
        property: 'og:locale',
        name: 'og:locale',
        content: 'zh_tw',
        vmid: 'og:locale',
      },
    ],
  };
}

function GetImageUrl(item) {
  return item == '' ? '' : process.env.VUE_APP_BASE_API + item;
}
