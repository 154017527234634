import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/reservation',
    name: 'reservation',
    component: () => import('../views/ReservationView.vue'),
  },
  {
    path: '/bike_articles',
    name: 'bike_articles',
    component: () => import('../views/BikeArticleListView.vue'),
  },
  {
    path: '/bike_article/:id',
    name: 'bike_article_page',
    component: () => import('../views/BikeArticleView.vue'),
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../views/NewsListView.vue'),
  },
  {
    path: '/news/:id',
    name: 'news_page',
    component: () => import('../views/NewsPageView.vue'),
  },
  {
    path: '/error_page',
    name: 'error_page',
    component: () => import('../views/ErrorPage.vue'),
  },
  {
    path: '*',
    redirect: '/error_page',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // 換頁滾動至最上面
  scrollBehavior: function (to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            selector: to.hash,
            behavior: 'smooth',
            offset: { x: 0, y: 50 },
          });
        }, 300);
      });
    }
    return { x: 0, y: 0 };
  },
});

export default router;
