<template>
  <section
    class="w-full relative z-10 rounded-[30px] sm:rounded-[50px] bg-primary"
  >
    <div
      class="w-full max-w-screen-xl flex-wrap mx-auto relative py-20 md:py-28 xl:px-0 sm:px-10 px-5 flex items-center justify-between"
    >
      <div class="lg:w-1/2 w-full pr-10 relative z-10">
        <p
          class="uppercase text-white leading-none mb-2 font-bold text-[40px] sm:text-[60px] md:text-[80px]"
        >
          reservation
        </p>
        <h4 class="text-2xl font-bold mb-10 text-black opacity-70">立即預約</h4>
        <p class="mb-10">
          如有單車租借需求，歡迎填寫資料，我們將主動與您聯絡！
        </p>
        <router-link
          to="/reservation"
          class="px-10 py-3 bg-white text-primary rounded-full font-bold transition-colors duration-300 border border-black hover:md:bg-black hover:md:text-white"
        >
          立即預約
        </router-link>
      </div>
      <div class="lg:w-1/2 w-full pl-10 flex justify-end relative z-10">
        <img
          v-lazy="'/img/contact_footer_scooter.webp'"
          @load="$RefreshScrollTrigger"
          alt="日月潭單車出租 電輔車出租"
          class="lg:w-full w-2/3 object-contain block"
        />
      </div>

      <img
        v-lazy="'/img/contact_text.webp'"
        @load="$RefreshScrollTrigger"
        alt="日月潭單車出租 電輔車出租"
        class="w-full absolute bottom-0 left-0 z-0 opacity-30"
      />
    </div>
  </section>
</template>

<script>
export default {
  name: 'ContactFooter',
};
</script>
