<template>
  <section id="ShopInfo" ref="MainContent" class="relative z-10 w-full py-20">
    <header class="text-center mb-28">
      <p
        data-section-title
        class="uppercase sm:text-[80px] text-[48px] leading-none sm:mb-5 mb-3 font-bold text-primary"
      >
        shop info
      </p>
      <h2 data-section-subtitle class="text-2xl font-bold">門市資訊</h2>
    </header>

    <div data-section-content>
      <div class="flex flex-wrap items-end justify-between w-full mb-10">
        <ol class="mb-5 md:mb-0">
          <li class="mb-1 font-bold">
            <span class="mr-5 font-bold text-zinc-500">聯絡電話</span
            >{{ $GetColumn('company_phone') }}
          </li>
          <li class="mb-1 font-bold">
            <span class="mr-5 font-bold text-zinc-500">聯絡地址</span
            >{{ $GetColumn('company_address') }}
          </li>
          <li class="font-bold">
            <span class="mr-5 font-bold text-zinc-500">營業時間</span
            >{{ $GetColumn('company_time') }}
          </li>
        </ol>

        <router-link
          to="/reservation"
          class="w-full px-10 py-3 font-bold text-white transition-colors duration-300 border border-black rounded-md bg-primary md:w-auto hover:md:bg-black"
        >
          立即預約
        </router-link>
      </div>

      <ol
        data-image-wrapper
        data-direction="5"
        class="flex items-stretch -mx-3"
      >
        <li
          data-image-box
          v-for="(item, item_index) in gallery_data"
          :key="`image_${item_index}`"
          class="flex-shrink-0 w-full px-3 md:w-1/3 sm:w-1/2"
        >
          <div class="w-full h-full">
            <img
              :src="item.Image1"
              :alt="item.Title"
              width="411"
              height="513"
              class="object-cover w-full h-full"
            />
          </div>
        </li>
      </ol>
    </div>
  </section>
</template>

<script>
import { CommonSection } from '@/gsap/CommonSection.js';
import { Marquee } from '@/gsap/Marquee';
export default {
  name: 'HomeShopInfoSection',
  data() {
    return {
      gallery_data: [
        {
          Title: '日月潭 電動自行車出租 自行車租出',
          Image1: '/img/shop_info/shop_info_gallery_1.webp',
        },
        {
          Title: '日月潭 電動自行車出租 自行車租出',
          Image1: '/img/shop_info/shop_info_gallery_2.webp',
        },
        {
          Title: '日月潭 電動自行車出租 自行車租出',
          Image1: '/img/shop_info/shop_info_gallery_3.webp',
        },
        {
          Title: '日月潭 電動自行車出租 自行車租出',
          Image1: '/img/shop_info/shop_info_gallery_4.webp',
        },
        {
          Title: '日月潭 電動自行車出租 自行車租出',
          Image1: '/img/shop_info/shop_info_gallery_5.webp',
        },
      ],
      section_gsap: null,
      marquee_gsap: null,
    };
  },
  methods: {
    SetGsap() {
      this.section_gsap.setup();
      this.marquee_gsap.setup();
    },
  },
  mounted() {
    this.section_gsap = new CommonSection(this.$refs.MainContent);
    this.section_gsap.reset();

    this.marquee_gsap = new Marquee(this.$refs.MainContent);
  },
};
</script>
