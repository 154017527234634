import { horizon_scroll } from '@/gsap/horizon_scroll';
import { gsap } from '@/gsap/GsapLoader';

export class Marquee extends horizon_scroll {
  constructor(el) {
    super();
    this.el = el;
    this.image_box = el.querySelector('[data-image-wrapper]');
    this.image_timeline = null;
    this.reset();
  }

  reset() {
    gsap.set(this.image_box, { xPercent: 0 });
  }

  setup() {
    const images = this.image_box.querySelectorAll('[data-image-box]');
    this.image_timeline = this.setScrollBackward(images);
  }

  pause() {
    this.image_timeline != null ? this.image_timeline.pause() : '';
  }
  resume() {
    this.image_timeline != null ? this.image_timeline.resume() : '';
  }
}
