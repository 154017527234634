import { gsap } from '@/gsap/GsapLoader';

export class MainFooter {
  constructor(el) {
    this.el = el;
    this.main_box = this.el.querySelector('[data-main-box]');
    this.timeline = null;
  }

  reset() {
    gsap.set(this.main_box, { y: '-100%' });
    this.timeline != null ? this.timeline.kill() : '';
    this.timeline = null;
  }

  setup() {
    this.timeline != null ? this.timeline.kill() : '';
    this.timeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.el,
        start: 'top bottom',
        end: 'bottom bottom',
        scrub: true,
        // markers: true,
      },
    });

    this.timeline.fromTo(
      this.main_box,
      {
        y: '-100%',
      },
      {
        y: '0%',
        ease: 'none',
      }
    );
  }
}
